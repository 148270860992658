<template>
  <div class="section-image">
    <div class="absolute box"/>
    <GanttChartImage class="absolute gantt"/>
    <PieChartImage class="absolute pie"/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import GanttChartImage from '@/assets/img/chart/gantt_chart.svg'
import PieChartImage from '@/assets/img/chart/pie_chart.svg'

@Options({
  components: {
    GanttChartImage,
    PieChartImage
  }
})
export default class FirstSectionImage extends Vue {

}
</script>
<style scoped>
.section-image {
  position: relative;
  width: 711px;
  height: 469px;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
}

.box {
  width: 256px;
  height: 200px;
  left: 393px;
  top: 268px;
  border-radius: 24px;
  background: #EAE8F2;
}

.pie {
  left: 538px;
  top: 54px;
}

.gantt {
  left: 50px;
  top: 20px;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
}
</style>
