<template>
  <div class="signup">
    <h1 class="title">Create your free Timenaut account</h1>
    <span class="subtitle">Welcome to Timenaut, Captain!</span>
    <form class="signup-form" @submit.prevent="register">
      <TextInput class="text-input"
                 label="What would you like us to call you?"
                 placeholder="Astro Naut"
                 v-model:value="name"
                 :error="nameError"
      />
      <TextInput class="text-input"
                 label="Your email"
                 placeholder="astro.naut@timenaut.app"
                 v-model:value="email"
                 :error="emailError"
      />
      <TextInput class="text-input"
                 label="Password"
                 placeholder="Password"
                 type="password"
                 v-model:value="password"
                 :error="passwordError"
      />
      <TextInput class="text-input"
                 label="Repeat password"
                 placeholder="Repeat password"
                 type="password"
                 v-model:value="password2"
                 :error="passwordError"
      />
      <div class="terms">
        <div>
          <Checkbox
              :checked="terms"
              @change="terms = $event.target.checked"
          ></Checkbox>
        </div>
        <span>I agree to the <a href="https://www.iubenda.com/terms-and-conditions/91116477">terms and conditions</a>
          and <br>
          <a href="https://www.iubenda.com/privacy-policy/91116477">privacy policy</a>.</span>
      </div>
      <div v-if="checkboxError !== ''" class="checkbox-error">
        {{ checkboxError }}
      </div>
      <Button class="button" type="submit">
        <template v-slot:icon-right>
          <ArrowRight/>
        </template>
        Create an account
      </Button>
    </form>

    <router-link to="/login">
      <TextButton class="text-button" dark>I already have an account</TextButton>
    </router-link>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button, Checkbox, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import axios, {AxiosError} from 'axios';
import ArrowRight from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/arrow-right.svg"
import {login} from "@/util/auth_util";

@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    Checkbox,
    ArrowRight,
  },
})
export default class SignupPage extends Vue {
  name: string = "";
  email: string = "";
  password: string = "";
  password2: string = "";
  terms: boolean = false;

  nameError: string = "";
  emailError: string = "";
  passwordError: string = "";
  checkboxError: string = "";

  emailRegex = RegExp(/.*@.*\..*/);

  async register() {
    this.nameError = "";
    this.emailError = "";
    this.passwordError = "";
    this.checkboxError = "";

    if (!this.terms) {
      this.checkboxError = "You must accept the terms and conditions";
      return;
    }

    if (!this.email.match(this.emailRegex)) {
      this.emailError = "Not a valid email";
      return;
    }

    if (this.password !== this.password2) {
      this.passwordError = "Passwords do not match";
      return;
    }

    if (this.password.length < 8) {
      this.passwordError = "Password must be longer than 8 characters";
      return;
    }

    axios.post(process.env.VUE_APP_API_URL + "/auth/register", {
      name: this.name,
      email: this.email,
      password: this.password,
    })
        .then(() => {
          login(this.email, this.password)
        })
        .catch((err: AxiosError) => {
          if (err.response?.data != null) {
            const data = err.response.data;
            if (data.code == "emailInUsed") {
              this.emailError = data.message
            } else if (data.code === "internalError") {
              this.nameError = "Unexpected error occurred"
            }
          } else {
            this.nameError = "Unexpected error occurred"
          }
        })
  }
}
</script>
<style scoped>
.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #16056B;
}

.subtitle {
  font-size: 12px;
  color: #16056B;
  margin-top: 14px;
  margin-bottom: 34px;
}

.signup-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
}

.text-input {
  margin: 12px 0;
  width: 320px;
}

.terms {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 24px 0;
}

.terms > span {
  margin-left: 16px;
  font-size: 13px;
  text-align: left;
  color: #16056B;
}

.text-button {
  margin: 16px;
}

a {
  color: #16056B;
  text-decoration: underline;
}

.button {
  align-self: center;
  width: 280px;
}

.checkbox-error {
  font-size: 11px;
  color: var(--red);
  margin-bottom: 8px;
}
</style>
