
import {Options, Vue} from 'vue-class-component';
import ThirdSectionImage from "@/components/image/ThirdSectionImage.vue";

@Options({
  components: {
    ThirdSectionImage
  }
})
export default class ThirdSection extends Vue {

}
