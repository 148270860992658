<template>
  <div class="feature-panel" :class="{'soon': soon}">
    <div class="icon-div" :style="{'background-color': color}" v-if="!soon">
      <div class="icon">
        <slot name="icon"></slot>
      </div>
    </div>
    <Tag v-else class="tag" color="#E5E7FA">Coming soon</Tag>
    <h3><slot name="title"/></h3>
    <p><slot/></p>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Tag} from "@timenaut/timenaut-vue-common"

@Options({
  props: {
    soon: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#D5F2EA"
    }
  },
  components: {
    Tag,
  }
})
export default class FeaturePanel extends Vue {
  soon!: boolean;
  color!: string;
}
</script>
<style scoped>
h3 {
  margin-top: 25px;
  margin-bottom: 2px;
}

.feature-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 30%;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 24px;

  margin: 40px 10px;
  padding: 30px;

  min-width: 327px;
  max-width: 400px;
}

.feature-panel.soon {
  box-shadow: none;
}

.icon-div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 86px;
  border-radius: 16px;
  margin-left: 50px;
  margin-right: 50px;
  width: 230px;
}

.soon {
  background-color: #F7F5F9;
  color: #9F92AD;
}

.icon {
  transform: scale(2);
}

.tag {
  color: #16056B;
  height: 22px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 17px;
  margin-top: 30px;
  margin-bottom: 30px;
}
</style>
