
import {Options, Vue} from 'vue-class-component';
import {Tag} from "@timenaut/timenaut-vue-common"

@Options({
  props: {
    soon: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: "#D5F2EA"
    }
  },
  components: {
    Tag,
  }
})
export default class FeaturePanel extends Vue {
  soon!: boolean;
  color!: string;
}
