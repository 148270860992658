<template>
  <div>
    <h1>Purchase cancelled</h1>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import ManagementNavigation from "@/components/ManagementNavigation.vue"

@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    ManagementNavigation
  },
})
export default class SuccessPage extends Vue {
}
</script>
<style scoped>
.full-height {
  height: 100%;
  background-color: #E5E5E5;
}

.signup {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
