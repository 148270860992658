
import {Options, Vue} from 'vue-class-component';
import {Button, TextInput} from '@timenaut/timenaut-vue-common'
import {defaultPriceInfo, getPriceDetails} from "@/util/price_fetch_util"

@Options({
  components: {
    TextInput,
    Button
  }
})
export default class PaidProductDescription extends Vue {
  priceInfo = defaultPriceInfo;

  mounted() {
    getPriceDetails().then(result => {
      this.priceInfo = result
    });
  }
}
