import axios from "axios";
import {router} from "@/router";
import {getStripeContext} from "@/stripe";

const TOKEN_KEY = "jwtToken";

export function isUserAuthenticated(): boolean {
    const token = localStorage.getItem(TOKEN_KEY)

    if (token == null) {
        return false;
    }

    const userInfoString = atob(token.split(".")[1]);
    const expirationDate = JSON.parse(userInfoString).exp;

    if (new Date(expirationDate * 1000) < new Date()) {
        return false;
    }

    return localStorage.getItem(TOKEN_KEY) !== null;
}

export function getUserEmail(): string | null {
    const token = localStorage.getItem(TOKEN_KEY)

    if (token == null) {
        return null;
    }

    const userInfoString = atob(token.split(".")[1]);
    return JSON.parse(userInfoString).sub;
}

export function saveAuthenticationData(authenticationData: string) {
    localStorage.setItem(TOKEN_KEY, authenticationData)
}

export function getJwtToken(): string {
    return localStorage.getItem(TOKEN_KEY)!
}

export function logOut() {
    localStorage.removeItem(TOKEN_KEY)
    router.push("/")
}

export async function login(email: string, password: string) {
    const response = await axios.post(process.env.VUE_APP_API_URL + "/auth/login", {
        email: email,
        password: password,
    });

    saveAuthenticationData(response.data);
    router.push("/user");
}

export async function redirectToStripeCheckout() {
    if (!isUserAuthenticated()) {
        router.push("/signup");
        return;
    }

    let stripe = await getStripeContext();
    axios.post(process.env.VUE_APP_API_URL + "/create-checkout-session", {}, {
        headers: {
            "Authorization": `Bearer ${getJwtToken()}`
        }
    })
        .then(response => {
            stripe.redirectToCheckout({sessionId: response.data.stripeSessionId})
        })
        .catch(err => {
            if (err.response?.status == "403") {
                router.push("/login");
            }
        })
}

export async function verifyUser(verificationCode: string) {
    axios.post(process.env.VUE_APP_API_URL + "/auth/verify", {
        verificationCode: verificationCode
    }).then(() => {
        router.push("/user")
    })
}

export async function resendConfirmationLink() {
    axios.post(process.env.VUE_APP_API_URL + "/auth/resend-verification", {}, {
        headers: {
            "Authorization": `Bearer ${getJwtToken()}`
        }
    })
}
