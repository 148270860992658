<template>
  <div class="full-height">
    <ManagementNavigation/>
    <router-view class="content"/>
    <!--    TODO: footer -->
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import ManagementNavigation from "@/components/ManagementNavigation.vue"

@Options({
  components: {
    ManagementNavigation
  },
})
export default class BaseTemplate extends Vue {
}
</script>
<style scoped>
.full-height {
  height: 100%;
  background-color: #F7F5F9;
}

.content {
  position: relative;
  z-index: 2;
}
</style>
