<template>
  <div class="section">
    <div class="third-section-text">
      <h2>Why combat procrastination?</h2>
      <p>We ourselves have underestimated procrastination. But there is so much to gain when<br> you have ways to tackle
        it.</p>
    </div>
    <div class="problems">
      <div class="problem-card">
        <div class="face-container">
          <AstronautFace3 class="face"/>
        </div>
        <h3>Motivation</h3>
        <p>We’ve all put off work waiting for motivation to come. But limit procrastination just a bit, and you’ll see
          signs of willpower sneaking up on you. Remember, action leads to motivation.</p>
      </div>
      <div class="problem-card">
        <div class="face-container">
          <AstronautFace1 class="face"/>
        </div>
        <h3>Happiness</h3>
        <p>According to Piers Steel, author of "The Procrastination Equation" 70% of procrastinators are less happy than
          the average person.
          Now that’s a big difference!</p>
      </div>
      <div class="problem-card">
        <div class="face-container">
          <AstronautFace2 class="face"/>
        </div>
        <h3>Career</h3>
        <p>Limiting procrastination will leave you with two career-enhancing options: achieve the same results in less
          time (and work less), or achieve more in the same time. </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import AstronautFace1 from '@/assets/img/face/face_1.svg';
import AstronautFace2 from '@/assets/img/face/face_2.svg';
import AstronautFace3 from '@/assets/img/face/face_3.svg';

@Options({
  components: {
    AstronautFace1,
    AstronautFace2,
    AstronautFace3,
  }
})
export default class extends Vue {

}
</script>

<style scoped>
.section {
  margin-top: 120px;
  text-align: center;
  margin-left: 120px;
  margin-right: 120px;

  padding-top: 60px;
  padding-bottom: 100px;

  background: linear-gradient(178.29deg, rgba(255, 255, 255, 0.39) 1.45%, rgba(255, 255, 255, 0) 98.55%), #EAE8F2;
  border-radius: 24px;
  width: calc(100% - 240px);
}

.problems {
  margin-top: 100px;
  display: flex;
  justify-content: center;
}

.problem-card {
  background-color: white;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  padding: 30px 30px 10px;
  margin: 12px;
  max-width: 225px;
}

p {
  line-height: 24px;
}

.face {
  border-radius: 16px;
}

.face-container {
  margin-top: -25%;
  position: relative;
}

h3 {
  margin-top: 24px;
  margin-bottom: 16px;
}

.problem-card > p {
  font-size: 14px;
  font-weight: 400;
}

@media (max-width: 1280px) {
  .section {
    width: 100%;
    border-radius: 0;
  }
}
</style>
