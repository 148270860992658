
import {Options, Vue} from 'vue-class-component';
import {Button, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import {login} from "@/util/auth_util";
import ArrowRight from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/arrow-right.svg"


@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    ArrowRight,
  },
})
export default class extends Vue {
  email: string = "";
  password: string = "";

  emailError: string = "";
  passwordError: string = "";

  async login() {
    try {
      await login(this.email, this.password)
    } catch (e: any) {
      if (e.response?.data != null) {
        const data = e.response.data;
        if (data.code == "invalidCredentials") {
          this.emailError = data.message;
          return;
        } else if (e.response.status === 403) {
          this.emailError = "Invalid credentials";
          return;
        }
      }
      this.emailError = "Unexpected error occurred"
    }
  }
}
