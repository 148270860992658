<template>
  <nav>
    <div class="nav-left">
      <router-link to="/">
        <Logo id="logo" viewBox="0 0 413 73"/>
      </router-link>
    </div>
    <div class="nav-right" v-if="isUserAuthenticated()">
      <span class="name">{{getUserEmail()}}</span>
      <DropdownButtonMenu
          class="dropdown-selection"
          :options="options"
          @selected="optionSelected"
          rightAlign/>
    </div>
  </nav>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Icon from "@timenaut/timenaut-vue-common/lib/assets/img/icon.svg";
import Logo from "@timenaut/timenaut-vue-common/lib/assets/img/timenaut-with-logo.svg";
import {DropdownButtonMenu} from "@timenaut/timenaut-vue-common";
import {isUserAuthenticated, logOut, getUserEmail} from "@/util/auth_util";

@Options({
  components: {
    Logo,
    Icon,
    DropdownButtonMenu
  }
})
export default class Navigation extends Vue {
  isUserAuthenticated = isUserAuthenticated;
  getUserEmail = getUserEmail;

  options = [
    "Logout"
  ]

  optionSelected(option: string) {
    if (option === "Logout") {
      logOut()
    }
  }
}
</script>
<style scoped>
nav {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  padding: 60px 100px 50px 120px;
  background-color: #F7F5F9;
}

.nav-left,
.nav-right {
  display: flex;
  align-items: center;
}

#logo {
  width: 233px;
  height: 42px;
  margin-right: 46px;
}

.name {
  font-size: 14px;
  font-weight: 400;
}

.dropdown-selection {
  margin-left: 16px;
}
</style>
