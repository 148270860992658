
import {Options, Vue} from 'vue-class-component';
import {Button, Checkbox, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import axios, {AxiosError} from 'axios';
import ArrowRight from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/arrow-right.svg"
import {login} from "@/util/auth_util";

@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    Checkbox,
    ArrowRight,
  },
})
export default class SignupPage extends Vue {
  name: string = "";
  email: string = "";
  password: string = "";
  password2: string = "";
  terms: boolean = false;

  nameError: string = "";
  emailError: string = "";
  passwordError: string = "";
  checkboxError: string = "";

  emailRegex = RegExp(/.*@.*\..*/);

  async register() {
    this.nameError = "";
    this.emailError = "";
    this.passwordError = "";
    this.checkboxError = "";

    if (!this.terms) {
      this.checkboxError = "You must accept the terms and conditions";
      return;
    }

    if (!this.email.match(this.emailRegex)) {
      this.emailError = "Not a valid email";
      return;
    }

    if (this.password !== this.password2) {
      this.passwordError = "Passwords do not match";
      return;
    }

    if (this.password.length < 8) {
      this.passwordError = "Password must be longer than 8 characters";
      return;
    }

    axios.post(process.env.VUE_APP_API_URL + "/auth/register", {
      name: this.name,
      email: this.email,
      password: this.password,
    })
        .then(() => {
          login(this.email, this.password)
        })
        .catch((err: AxiosError) => {
          if (err.response?.data != null) {
            const data = err.response.data;
            if (data.code == "emailInUsed") {
              this.emailError = data.message
            } else if (data.code === "internalError") {
              this.nameError = "Unexpected error occurred"
            }
          } else {
            this.nameError = "Unexpected error occurred"
          }
        })
  }
}
