
import {Options, Vue} from 'vue-class-component';
import Icon from "@timenaut/timenaut-vue-common/lib/assets/img/icon.svg";
import Logo from "@timenaut/timenaut-vue-common/lib/assets/img/timenaut-with-logo.svg";
import {DropdownButtonMenu} from "@timenaut/timenaut-vue-common";
import {isUserAuthenticated, logOut, getUserEmail} from "@/util/auth_util";

@Options({
  components: {
    Logo,
    Icon,
    DropdownButtonMenu
  }
})
export default class Navigation extends Vue {
  isUserAuthenticated = isUserAuthenticated;
  getUserEmail = getUserEmail;

  options = [
    "Logout"
  ]

  optionSelected(option: string) {
    if (option === "Logout") {
      logOut()
    }
  }
}
