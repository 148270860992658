import axios from 'axios';

type PriceResponse = {
    price: number,
    salePrice?: number,
    discountAmount?: number,
    saleEndDate?: string
}

type PriceInfo = {
    currentPrice: string,
    currentPriceWhole: string,
    currentPriceCents: string
    isSale: boolean,
    discountAmount?: string,
    saleEndDate?: string
}

export const defaultPriceInfo: PriceInfo = {
    currentPrice: "30.00",
    currentPriceWhole: "30",
    currentPriceCents: "00",
    isSale: false
}

export async function getPriceDetails(): Promise<PriceInfo> {
    const response = await axios.get(process.env.VUE_APP_API_URL + "/public/price");
    const responseData: PriceResponse = response.data;

    let price: string;

    if (responseData.salePrice === undefined) {
        price = (responseData.price / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    } else {
        price = (responseData.salePrice / 100).toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        });
    }

    return {
        currentPrice: price,
        currentPriceWhole: price.substring(0, 2),
        currentPriceCents: price.substring(3, 5),
        isSale: responseData.salePrice !== undefined,
        discountAmount: responseData.discountAmount === undefined ? undefined : responseData.discountAmount.toString(),
        saleEndDate: responseData.saleEndDate
    }
}
