
import {Options, Vue} from 'vue-class-component';
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Landing from '@/components/Landing.vue'
import FirstSection from '@/components/section/FirstSection.vue'
import SecondSection from '@/components/section/SecondSection.vue'
import ThirdSection from '@/components/section/ThirdSection.vue'
import ProblemsSection from '@/components/section/ProblemsSection.vue'
import FeaturesSection from '@/components/section/features/FeaturesSection.vue'
import PricingSection from '@/components/section/PricingSection.vue'
import StorySection from '@/components/section/StorySection.vue'
import DownloadSection from '@/components/section/DownloadSection.vue'

@Options({
  components: {
    Navigation,
    Landing,
    FirstSection,
    SecondSection,
    ThirdSection,
    ProblemsSection,
    FeaturesSection,
    PricingSection,
    StorySection,
    DownloadSection,
    Footer
  },
})
export default class HomePage extends Vue {
}
