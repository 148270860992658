<template>
  <div id="landing">
    <h1>Combat procrastination<br>with data</h1>
    <p>Find your hidden habits with zero effort</p>
    <div class="center-button">
      <router-link to="/download">
        <Button class="pink-button">Download for free</Button>
      </router-link>
    </div>
    <div class="download-info">
      <span class="os-text">Timenaut works with:</span>
      <div class="os-icons">
        <WindowsIcon/>
        <MaxOsIcon/>
        <LinuxIcon/>
      </div>
    </div>
    <div class="svg-div">
      <AppSvg class="app-svg" viewBox="0 0 1441 900"/>
      <div class="svg-fade"></div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button} from "@timenaut/timenaut-vue-common";
import WindowsIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/windows.svg";
import MaxOsIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/macos.svg";
import LinuxIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/linux.svg";
import AppSvg from "@/assets/img/app.svg";

@Options({
  components: {
    Button,
    WindowsIcon,
    MaxOsIcon,
    LinuxIcon,
    AppSvg
  }
})
export default class Landing extends Vue {

}
</script>
<style scoped>
h1, p, span {
  color: var(--white);
}

h1 {
  margin-top: 100px;
  font-size: 52px;
  font-weight: 600;
}

p {
  font-size: 18px;
  font-weight: 400;
  margin-top: 26px;
  margin-bottom: 30px;
}

#landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
}

.download-info {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  margin-bottom: 24px;
  margin-top: 20px;
}

.os-text {
  padding-top: 2px;
}

.os-icons {
  display: inline-block;
  margin-left: 8px;
  padding-top: 4px;
}

.os-icons svg {
  margin-left: 8px;
  margin-right: 8px;
}

.center-button {
  display: flex;
  justify-content: center;
}

.button.pink-button {
  min-width: 240px;
  background-color: #f4b4c7;
  color: var(--accent);
}

.button.pink-button:active,
.button.pink-button:hover {
  background-color: #eedff2;
}

.button.pink-button:active {
  border-color: #dba5f5;
}

.iframe-div {
  width: 100%;
  overflow: hidden;

  height: 600px;
  position: relative;
}

#frame {
  border: none;
  border-radius: 8px;

  transform: scale(0.75);
  transform-origin: top center;
  -ms-zoom: 0.75;
  -moz-transform: scale(0.75);
  -moz-transform-origin: top center;
  -o-transform: scale(0.75);
  -o-transform-origin: top center;
  -webkit-transform: scale(0.75);
  -webkit-transform-origin: top center;
}

@media (max-width: 1280px) {
  #frame {
    width: 1280px;
  }
}

.svg-div {
  display: inline-block;
  position: relative;
  border-radius: 8px;
  width: 1049px;
  height: 650px;
}

.app-svg {
  width: 1049px;
  height: 650px;
  border-radius: 0;
  filter: drop-shadow(0px 7px 64px rgba(0, 0, 0, 0.07));
}

.svg-fade {
  position: absolute;
  bottom: 0;
  right: 5px;
  background: linear-gradient(180deg, rgba(247, 245, 249, 0.29) 0%, #F7F5F9 100%);
  width: 1039px;
  height: 80px;
}
</style>
