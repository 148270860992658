<template>
  <div class="full-height">
    <ManagementNavigation/>
    <router-view class="content"/>
    <div class="background">
      <Background class="background"></Background>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Background from "@timenaut/timenaut-vue-common/lib/assets/img/login_bg.svg";
import ManagementNavigation from "@/components/ManagementNavigation.vue"

@Options({
  components: {
    Background,
    ManagementNavigation
  },
})
export default class AuthTemplate extends Vue {
}
</script>
<style scoped>
.full-height {
  height: 100%;
  background-color: #F7F5F9;
}

.content {
  position: relative;
  z-index: 2;
}

.background {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1;
}
</style>
