<template>
  <div class="section">
    <h2>How Timenaut was born</h2>
    <div class="story-section">
      <div class="image">
        <div class="image-background"></div>
        <StarSvg class="star-1"/>
        <StarSvg class="star-2" viewBox="0 0 32 32"/>
        <StarSvg class="star-3" viewBox="0 0 32 32"/>
        <img src="@/assets/img/us.png" alt="Us" width="518" height="346">
      </div>
      <div class="text">
        <p>When two procrastinators meet, you know nothings gonna happen.
          <br>
          <br>
          Fortunately we were both hungry to change that. Being computer science students in the land of startups and
          unicorns we made Timenaut.
          <br>
          <br>
          Our goal by doing so was to help people find their weak spots in time management and try to improve it,
          because if tracking your sleep, food and steps is a mainstream thing now, why shouldn’t we track time spent on
          our computer as well?
          <br>
          <br>
          Now we know that when big brother sees what we’re up to all the time that might become a bit of a problem for
          our wallet.
          Thats why since day one we decided that privacy and security will be part of our vision and purpose to develop
          a customer first time management software!</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import StarSvg from "@/assets/img/star.svg"

@Options({
  components: {
    StarSvg
  }
})
export default class StorySection extends Vue {

}
</script>
<style scoped>
.section {
  text-align: center;
  margin: 120px 120px 180px;
  max-width: 1440px;
}

.story-section {
  display: flex;
}

.image {
  position: relative;
  margin-top: 60px;
  margin-right: 80px;
}

.image-background {
  position: absolute;
  top: 190px;
  left: -30px;
  width: 256px;
  height: 200px;
  background-color: #EAE8F2;
  border-radius: 24px;
  z-index: -1;
}

.star-1 {
  position: absolute;
  top: -73px;
  left: 40px;
}

.star-2 {
  position: absolute;
  top: 450px;
  left: 300px;
  width: 18px;
  height: 18px;
}

.star-3 {
  position: absolute;
  top: 400px;
  left: 430px;
  width: 37px;
  height: 37px;
}

.text {
  text-align: left;
}

@media (max-width: 1280px) {
  .story-section {
    flex-direction: column;
    align-items: center;
  }

  .image {
    width: 518px;
    height: 346px;
    margin-bottom: 140px;
    margin-right: 0;
  }
}
</style>
