import {createRouter, createWebHistory, NavigationGuardNext, RouteLocationNormalized, RouteRecordRaw} from "vue-router";
import HomePage from "@/pages/HomePage.vue";
import LoginPage from "@/pages/auth/LoginPage.vue";
import SignupPage from "@/pages/auth/SignupPage.vue";
import SuccessPage from "@/pages/SuccessPage.vue";
import CancelPage from "@/pages/CancelPage.vue";
import DownloadPage from "@/pages/DownloadPage.vue";
import AuthTemplate from "@/templates/AuthTemplate.vue";
import BaseTemplate from "@/templates/BaseTemplate.vue";
import LoggedInTemplate from "@/templates/LoggedInTemplate.vue";
import UserPage from "@/pages/user/UserPage.vue";
import {isUserAuthenticated} from "@/util/auth_util";

const routes: RouteRecordRaw[] = [
    {
        path: "",
        alias: "/",
        component: HomePage,
    },
    {
        path: "",
        component: AuthTemplate,
        beforeEnter: alreadyLoggedInCheck,
        children: [
            {
                path: "/login",
                component: LoginPage,
            },
            {
                path: "/signup",
                component: SignupPage,
            },
        ]
    },
    {
        path: "",
        component: LoggedInTemplate,
        beforeEnter: mustBeLoggedInCheck,
        children: [
            {
                path: "/user",
                component: UserPage,
            },
        ]
    },
    {
        path: "",
        component: LoggedInTemplate,
        beforeEnter: mustBeLoggedInCheck,
        children: [
            {
                path: "/user/verify/:verificationCode",
                component: UserPage,
            },
        ]
    },
    {
        path: "",
        component: BaseTemplate,
        children: [
            {
                path: "/success",
                component: SuccessPage,
            },
            {
                path: "/cancel",
                component: CancelPage,
            },
            {
                path: "/download",
                component: DownloadPage,
            },
        ]
    },
]

function alreadyLoggedInCheck(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    if (isUserAuthenticated()) {
        next("/user");
    } else {
        next()
    }
}

function mustBeLoggedInCheck(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
) {
    if (!isUserAuthenticated()) {
        next("/login");
    } else {
        next()
    }
}

const router = createRouter({
    history: createWebHistory(),
    routes: routes
});

export {
    routes,
    router
}
