<template>
  <div class="free-details">
    <span class="your-details">You are using the free version of Timenaut</span>
    <p class="details">The free version of Timenaut only shows two weeks of tracking history and does not contain some <router-link to="/#pricing">Premium Features</router-link></p>
    <Button @click="redirectToStripeCheckout">Buy Timenaut now for {{ priceInfo.currentPrice }} €</Button>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button, TextInput} from '@timenaut/timenaut-vue-common'
import {defaultPriceInfo, getPriceDetails} from "@/util/price_fetch_util"
import { redirectToStripeCheckout } from '@/util/auth_util';

@Options({
  components: {
    TextInput,
    Button
  }
})
export default class FreeProductDescription extends Vue {
  priceInfo = defaultPriceInfo;

  redirectToStripeCheckout = redirectToStripeCheckout;

  mounted() {
    getPriceDetails().then(result => {
      this.priceInfo = result
    });
  }
}
</script>
<style scoped>
.free-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: #FF647C solid 8px;
  border-radius: 4px;
  padding: 24px;
}

.your-details {
  color: #FF647C;
  margin: 16px 0;
  font-weight: 600;
  font-size: 21px;
}

.details {
  font-size: 14px;
  text-align: left;
  line-height: 20px;
}

a {
  font-weight: 700;
  color: #379FFF;
  text-decoration: none;
}
</style>
