
import {Options, Vue} from 'vue-class-component';
import GanttChartImage from '@/assets/img/chart/gantt_chart.svg'
import PieChartImage from '@/assets/img/chart/pie_chart.svg'

@Options({
  components: {
    GanttChartImage,
    PieChartImage
  }
})
export default class FirstSectionImage extends Vue {

}
