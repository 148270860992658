<template>
  <footer :class="{dark: dark}">
    <div class="footer-content">
      <div class="footer-column social">
        <TimenautSvg width="100" height="13" viewBox="0 0 314 40"/>
        <div class="social">
          <a href="https://www.facebook.com/timenautapp">
            <FacebookSvg class="social-icon facebook-icon"/>
          </a>
          <a href="https://www.linkedin.com/company/timenaut">
            <LinkedinSvg class="social-icon"/>
          </a>
        </div>
        <span class="copyright">Copyright @ 2021 Timenaut</span>
      </div>
      <div class="filler" :class="dark"/>
      <div class="footer-column">
        <router-link to="/">About Timenaut</router-link>
        <router-link to="/download">Download</router-link>
        <router-link to="/login">Login</router-link>
        <router-link to="/signup">Sign up</router-link>
      </div>
      <div class="footer-column">
        <a href="mailto:support@timenaut.app">Contact support</a>
        <a href="https://www.iubenda.com/privacy-policy/91116477">Privacy Policy</a>
        <a href="https://www.iubenda.com/terms-and-conditions/91116477">Terms and Conditions</a>
        <router-link to="/user">Account info</router-link>
      </div>
      <div class="filler" :class="dark"/>
      <div class="footer-column">
        <span class="light-color">Timenaut OÜ</span>
        <span class="light-color">Tartu, Estonia</span>
        <span class="light-color">16130176</span>
        <a href="mailto:info@timenaut.app">info@timenaut.app</a>
      </div>
    </div>
  </footer>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import TimenautSvg from "@timenaut/timenaut-vue-common/lib/assets/img/timenaut.svg"
import FacebookSvg from "@timenaut/timenaut-vue-common/lib/assets/icons/logos/facebook.svg"
import LinkedinSvg from "@timenaut/timenaut-vue-common/lib/assets/icons/logos/linkedin.svg"

@Options({
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TimenautSvg,
    FacebookSvg,
    LinkedinSvg,
  }
})
export default class extends Vue {
  dark!: boolean
}
</script>
<style scoped>
footer {
  margin: 80px 120px;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-content {
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

a {
  text-decoration: none;
  color: var(--accent);
}

span, a {
  font-size: 14px;
  color: #3F3356;
}

.footer-column {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.copyright {
  font-size: 11px;
}

.light-color {
  color: #AEA8CC;
}

.dark .light-color {
  color: #3F3356;
}

.filler {
  width: 1px;
  background-color: #D0C9D6;
}

.dark .filler {
  background-color: #ffffff;
}

.social-icon {
  background-color: white;
  border-radius: 2px;
  margin-right: 12px;
}

.facebook-icon {
  border-radius: 8px;
}
</style>
