<template>
  <div class="user-details">
    <div class="details">
      <span class="your-details">Your details</span>
      <TextInput label="What we call you"
                 class="text-input"
                 :value="userDetails?.name"
                 disabled
      />
      <TextInput label="Email address"
                 class="text-input"
                 :value="userDetails?.email"
                 disabled
      />
      <TextButton>Change password</TextButton>
    </div>
    <div class="picture">
      <ProfilePictureSvg/>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {TextButton, TextInput} from '@timenaut/timenaut-vue-common'
import {UserResponse} from "@/util/user_util";
import ProfilePictureSvg from "@timenaut/timenaut-vue-common/lib/assets/img/profile-picture.svg"

@Options({
  components: {
    TextInput,
    TextButton,
    ProfilePictureSvg,
  },
  props: {
    userDetails: {
      type: Object
    }
  }
})
export default class UserDetails extends Vue {
  userDetails?: UserResponse;
}
</script>
<style scoped>
.user-details {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;

  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  padding: 24px;
  border-radius: 24px;
}

.your-details {
  align-self: flex-start;
  color: #16056B;
  size: 17px;
  font-weight: 600;
}

.text-input {
  width: 100%;
  margin: 12px 0;
}

.details {
  display: flex;
  flex-direction: column;
}

.picture {
  display: flex;
  align-items: center;
  padding-right: 40px;
}
</style>
