
import {Options, Vue} from 'vue-class-component';
import ProductivityCardImage from '@/assets/img/chart/productivity_card.svg'
import ProductiveHoursCardImage from '@/assets/img/chart/productive_hours_card.svg'
import TotalTimeCardImage from '@/assets/img/chart/total_time_card.svg'
import DistractedTimeCardImage from '@/assets/img/chart/distracted_time_card.svg'
import PieChart1Image from '@/assets/img/chart/pie_charts/pie_chart_1.svg'
import PieChart2Image from '@/assets/img/chart/pie_charts/pie_chart_2.svg'
import PieChart3Image from '@/assets/img/chart/pie_charts/pie_chart_3.svg'
import PieChart4Image from '@/assets/img/chart/pie_charts/pie_chart_4.svg'
import PieChart5Image from '@/assets/img/chart/pie_charts/pie_chart_5.svg'

@Options({
  components: {
    ProductivityCardImage,
    ProductiveHoursCardImage,
    TotalTimeCardImage,
    DistractedTimeCardImage,
    PieChart1Image,
    PieChart2Image,
    PieChart3Image,
    PieChart4Image,
    PieChart5Image,
  }
})
export default class ThirdSectionImage extends Vue {

}
