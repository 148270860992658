<template>
  <router-view></router-view>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';

@Options({

})
export default class Webpage extends Vue {
}
</script>
<style src="@timenaut/timenaut-vue-common/lib/assets/css/common.css">
</style>
<style src="@timenaut/timenaut-vue-common/lib/assets/fonts/inter/inter.css">
</style>
<style src="@timenaut/timenaut-vue-common/lib/timenaut-vue-common.esm.css">
</style>
<style scoped>
.limit-width {
  max-width: 1440px;
}

.max-width {
  width: 100%;
}
</style>
<style>
html, body {
  height: 100%;
  margin: 0;
  font-family: Inter, sans-serif;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

h2 {
  font-weight: 600;
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
}

h3 {
  font-weight: 600;
  font-size: 21px;
  margin-top: 40px;
  margin-bottom: 40px;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

a {
  text-decoration: none;
  color: #16056B;
}
</style>
