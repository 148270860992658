<template>
  <div class="section" id="features">
    <h2>Timenaut is full of features to help you focus</h2>
    <div class="features">
      <FeaturePanel color="#D5F2EA">
        <template v-slot:icon>
          <ClockIcon style="color: #00C48C" stroke-width="1px"/>
        </template>
        <template v-slot:title>
          Automatically track your time<br/><span style="color: #00C48C">without any manual entries</span>
        </template>
        No need to push any buttons. Timenaut automatically tracks your time spent on apps and websites.
      </FeaturePanel>
      <FeaturePanel color="#DFEEFF">
        <template v-slot:icon>
          <GridIcon style="color: #379FFF" stroke-width="1px"/>
        </template>
        <template v-slot:title>
          Data gets <span style="color: #379FFF">organized and categorized</span> automatically
        </template>
        Timenaut automatically categorises apps and websites based on your acitivity.
      </FeaturePanel>
      <FeaturePanel color="#EAE8F2">
        <template v-slot:icon>
          <BarChartIcon style="color: #16056B" stroke-width="1px"/>
        </template>
        <template v-slot:title>
          Beautiful <span style="color: #16056B">reports and insights,</span><br/> based on your data
        </template>
        Because looking back does not have to be an ugly thing.
      </FeaturePanel>
      <FeaturePanel color="#FFECD6">
        <template v-slot:icon>
          <PocketIcon style="color: #FD9519" stroke-width="1px"/>
        </template>
        <template v-slot:title>
          Your <span style="color: #FD9519">data is always secure</span> and<br> private with Timenaut
        </template>
        All collected tracking data sent to our servers is encrypted to guarantee your privacy.
      </FeaturePanel>
      <FeaturePanel soon>
        <template v-slot:title>
          Block distracting websites to improve your focus
        </template>
        Timenaut blocks distracting websites to make sure you can focus on whats important.
      </FeaturePanel>
      <FeaturePanel soon>
        <template v-slot:title>
          Personalised tips and tricks to improve focus
        </template>
        Weekly tips and tricks that will help you become your best productive self.
      </FeaturePanel>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import FeaturePanel from "@/components/section/features/FeaturePanel.vue";
import ClockIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/clock.svg"
import GridIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/grid.svg"
import BarChartIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/bar-chart.svg"
import PocketIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/pocket.svg"

@Options({
  components: {
    FeaturePanel,
    ClockIcon,
    GridIcon,
    BarChartIcon,
    PocketIcon,
  }
})
export default class FeaturesSection extends Vue {

}
</script>
<style scoped>
.section {
  margin-top: 120px;
  text-align: center;
  margin-left: 120px;
  margin-right: 120px;
}

.features {
  display: inline-flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (max-width: 1280px) {
  .section {
    margin-left: 0;
    margin-right: 0;
  }
}
</style>
