<template>
  <div id="about" class="section">
    <div class="first-section-text">
      <h2>See exactly where your time<br>goes, no effort needed</h2>
      <p>Timenaut looks for active programs in your computer to see what you are focusing on. This way you don't need to
        worry about remembering to press any buttons before starting your work, everything is done for you.</p>
    </div>
    <div class="first-section-svg-div">
      <FirstSectionImage/>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import FirstSectionImage from "@/components/image/FirstSectionImage.vue";

@Options({
  components: {
    FirstSectionImage
  }
})
export default class FirstSection extends Vue {

}
</script>
<style scoped>
h2 {
  font-weight: 600;
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.section {
  text-align: left;
  display: grid;
  grid-template-columns: 50% auto;
  margin-left: 120px;
  margin-right: 120px;
}

.first-section-text {
  margin-right: 120px;
}

.first-section-svg-div {
  overflow: hidden;
  text-align: right;
  margin-top: -80px;
  margin-right: -120px;
}

img {
  image-rendering: -webkit-optimize-contrast;
}
</style>
