<template>
  <div class="free-details">
    <span class="your-details">Lifetime access to Timenaut</span>
    <p class="details">You have purchased Timenaut and you now have a lifetime access to Timenaut.</p>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button, TextInput} from '@timenaut/timenaut-vue-common'
import {defaultPriceInfo, getPriceDetails} from "@/util/price_fetch_util"

@Options({
  components: {
    TextInput,
    Button
  }
})
export default class PaidProductDescription extends Vue {
  priceInfo = defaultPriceInfo;

  mounted() {
    getPriceDetails().then(result => {
      this.priceInfo = result
    });
  }
}
</script>
<style scoped>
.free-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-left: #379FFF solid 8px;
  border-radius: 4px;
  padding: 24px;
}

.your-details {
  color: #379FFF;
  margin: 16px 0;
  font-weight: 600;
  font-size: 21px;
}

.details {
  font-size: 14px;
  text-align: left;
  line-height: 20px;
}

a {
  font-weight: 700;
  color: #379FFF;
  text-decoration: none;
}
</style>
