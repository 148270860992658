import {loadStripe, Stripe} from '@stripe/stripe-js';

let stripe: Stripe | null = null;

export async function getStripeContext(): Promise<Stripe> {
    if (stripe == null) {
        stripe = (await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_API_KEY))!;
    }
    return stripe;
}
