
import {Options, Vue} from 'vue-class-component';
import AstronautFace1 from '@/assets/img/face/face_1.svg';
import AstronautFace2 from '@/assets/img/face/face_2.svg';
import AstronautFace3 from '@/assets/img/face/face_3.svg';

@Options({
  components: {
    AstronautFace1,
    AstronautFace2,
    AstronautFace3,
  }
})
export default class extends Vue {

}
