<template>
  <div id="pricing" class="section">
    <h2>Pricing</h2>
    <div class="options">
      <div class="panel">
        <div class="panel-info">
          <h2>Free plan</h2>
          <p>No commitment, no credit card details needed. Limited features.</p>
          <div class="price-box">
            <span class="price">€<span class="big">0</span>.00</span>
          </div>
          <div class="feature">
            <Selector selected class="selector"/>
            <span>Automatic tracking and categorising</span>
          </div>
          <div class="feature">
            <Selector selected class="selector"/>
            <span>Reports and insights</span>
          </div>
          <div class="feature">
            <Selector selected class="selector"/>
            <span>History for 2 weeks</span>
          </div>
        </div>
        <Button class="button">Try for free</Button>
      </div>
      <div class="panel">
        <div v-if="priceInfo.isSale" class="limited-offer">LIMITED OFFER</div>
        <div class="panel-info">
          <h2>One-time payment</h2>
          <p>Pay once, get all future updates for free. New features added for free.</p>
          <div :class="{'sale': priceInfo.isSale}">
            <div class="price-box">
              <span class="price">€<span class="big">{{
                  priceInfo.currentPriceWhole
                }}</span>.{{ priceInfo.currentPriceCents }}</span>
            </div>
            <div v-if="priceInfo.isSale" class="sale-box">
              <span><b>-{{ priceInfo.discountAmount }}% OFF</b></span>
              <span>unit {{ priceInfo.saleEndDate }}</span>
            </div>
            <div class="feature">
              <Selector selected class="selector"/>
              <span>Automatic tracking and categorising</span>
            </div>
            <div class="feature">
              <Selector selected class="selector"/>
              <span>Reports and insights</span>
            </div>
            <div class="feature">
              <Selector selected class="selector"/>
              <span>Unlimited history</span>
            </div>
            <div class="feature">
              <Selector selected class="selector"/>
              <span>All new features for free</span>
            </div>
          </div>
        </div>
        <Button @click="redirectToStripeCheckout" class="button">Purchase</Button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button, Selector} from "@timenaut/timenaut-vue-common"
import axios from 'axios';
import {getStripeContext} from '@/stripe.ts';
import {defaultPriceInfo, getPriceDetails} from "@/util/price_fetch_util";
import {getJwtToken, isUserAuthenticated, redirectToStripeCheckout} from "@/util/auth_util";

@Options({
  components: {
    Button,
    Selector
  }
})
export default class ThirdSection extends Vue {
  priceInfo = defaultPriceInfo;
  redirectToStripeCheckout = redirectToStripeCheckout;

  mounted() {
    getPriceDetails().then(result => {
      this.priceInfo = result
    });
  }
}
</script>
<style scoped>
.section {
  margin-top: 120px;
  text-align: center;
  padding-top: 60px;
  padding-bottom: 50px;
  background-color: #379FFF;
}

.options {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  text-align: left;
}

.panel-info > h2 {
  margin-bottom: 10px;
}

.panel {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
  border-radius: 24px;
  padding: 30px 40px;
  width: 370px;
  margin: 10px;
}

.panel-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: white;
  margin-bottom: 20px;
}

p {
  font-size: 18px;
}

.section > h2 {
  color: white;
}

.sale .price {
  color: #00C48C;
}

.price {
  text-align: justify;
  vertical-align: top;
  font-size: 18px;
}

.price .big {
  vertical-align: top;
  font-size: 52px;
  line-height: 1;
}

.price-box {
  display: inline-flex;
  margin-right: 20px;
  margin-bottom: 16px;
}

.sale-box {
  display: inline-flex;
  flex-direction: column;
  padding: 6px 16px;
  color: white;
  background-color: #00C48C;
  border-radius: 4px;
}

.limited-offer {
  margin-top: -56px;
  text-align: center;
  line-height: 52px;
  background-color: #FFCF5C;
  width: 272px;
  height: 52px;
  border-radius: 16px;
}

.button {
  justify-self: flex-end;
  min-width: 200px;
}

.selector {
  vertical-align: middle;
  margin: 8px 16px 8px 8px;
}
</style>
