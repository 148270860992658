
import {Options, Vue} from 'vue-class-component';
import ManagementNavigation from "@/components/ManagementNavigation.vue"
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    ManagementNavigation,
    Footer,
  },
})
export default class BaseTemplate extends Vue {
}
