
import {Options, Vue} from 'vue-class-component';
import Icon from "@timenaut/timenaut-vue-common/lib/assets/img/icon.svg";
import Logo from "@timenaut/timenaut-vue-common/lib/assets/img/timenaut-with-logo-light.svg";
import {Button} from "@timenaut/timenaut-vue-common";
import {isUserAuthenticated, getUserEmail} from "@/util/auth_util";

@Options({
  components: {
    Logo,
    Icon,
    Button
  }
})
export default class Navigation extends Vue {
  isUserAuthenticated = isUserAuthenticated;
  getUserEmail = getUserEmail;
}
