
import {Options, Vue} from 'vue-class-component';
import {Button, Selector} from "@timenaut/timenaut-vue-common"
import axios from 'axios';
import {getStripeContext} from '@/stripe.ts';
import {defaultPriceInfo, getPriceDetails} from "@/util/price_fetch_util";
import {getJwtToken, isUserAuthenticated, redirectToStripeCheckout} from "@/util/auth_util";

@Options({
  components: {
    Button,
    Selector
  }
})
export default class ThirdSection extends Vue {
  priceInfo = defaultPriceInfo;
  redirectToStripeCheckout = redirectToStripeCheckout;

  mounted() {
    getPriceDetails().then(result => {
      this.priceInfo = result
    });
  }
}
