<template>
  <div class="full-height">
    <ManagementNavigation/>
    <router-view class="content"/>
    <Footer class="footer"/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import ManagementNavigation from "@/components/ManagementNavigation.vue"
import Footer from "@/components/Footer.vue";

@Options({
  components: {
    ManagementNavigation,
    Footer,
  },
})
export default class BaseTemplate extends Vue {
}
</script>
<style scoped>
.full-height {
  height: 100vh;
  background-color: #F7F5F9;
}

.content {
  padding-left: 120px;
  padding-right: 100px;
  position: relative;
  z-index: 2;
  height: calc(100vh - 156px - 240px);
}

.footer {
  background-color: #F7F5F9;
}
</style>
