
import {Options, Vue} from 'vue-class-component';
import ManagementNavigation from "@/components/ManagementNavigation.vue"

@Options({
  components: {
    ManagementNavigation
  },
})
export default class BaseTemplate extends Vue {
}
