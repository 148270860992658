<template>
  <div class="login">
    <h1 class="title">Log into your Timenaut account</h1>
    <span class="subtitle">Welcome back, Captain!</span>
    <form class="login-form" @submit.prevent="login">
      <TextInput
          class="text-input"
          label="Your email"
          placeholder="astro.naut@timenaut.app"
          v-model:value="email"
          :error="emailError"
      />
      <TextInput
          class="text-input"
          label="Password"
          placeholder="Password"
          type="password"
          v-model:value="password"
          :error="passwordError"
      />
      <Button type="submit" class="button">
        <template v-slot:icon-right>
          <ArrowRight/>
        </template>
        Login
      </Button>
    </form>
    <router-link to="/signup">
      <TextButton class="text-button" dark>Create an account instead</TextButton>
    </router-link>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import {login} from "@/util/auth_util";
import ArrowRight from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/arrow-right.svg"


@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    ArrowRight,
  },
})
export default class extends Vue {
  email: string = "";
  password: string = "";

  emailError: string = "";
  passwordError: string = "";

  async login() {
    try {
      await login(this.email, this.password)
    } catch (e: any) {
      if (e.response?.data != null) {
        const data = e.response.data;
        if (data.code == "invalidCredentials") {
          this.emailError = data.message;
          return;
        } else if (e.response.status === 403) {
          this.emailError = "Invalid credentials";
          return;
        }
      }
      this.emailError = "Unexpected error occurred"
    }
  }
}
</script>
<style scoped>
.login {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #16056B;
}

.subtitle {
  font-size: 12px;
  color: #16056B;
  margin-top: 14px;
  margin-bottom: 34px;
}

.text-input {
  margin: 12px 0;
  width: 320px;
}

.button {
  margin-top: 20px;
  width: 280px;
}

.text-button {
  margin: 16px;
}
</style>
