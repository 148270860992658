import { render } from "./Webpage.vue?vue&type=template&id=5d400aec&scoped=true"
import script from "./Webpage.vue?vue&type=script&lang=ts"
export * from "./Webpage.vue?vue&type=script&lang=ts"

import "@timenaut/timenaut-vue-common/lib/assets/css/common.css?vue&type=style&index=0&lang=css"
import "@timenaut/timenaut-vue-common/lib/assets/fonts/inter/inter.css?vue&type=style&index=1&lang=css"
import "@timenaut/timenaut-vue-common/lib/timenaut-vue-common.esm.css?vue&type=style&index=2&lang=css"
import "./Webpage.vue?vue&type=style&index=3&id=5d400aec&scoped=true&lang=css"
import "./Webpage.vue?vue&type=style&index=4&id=5d400aec&lang=css"
script.render = render
script.__scopeId = "data-v-5d400aec"

export default script