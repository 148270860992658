<template>
  <div class="section">
    <div class="second-section-svg-div">
      <SecondSectionImage/>
    </div>
    <div class="second-section-text">
      <h2>Understand your work habits<br>with detailed insights</h2>
      <p>Beauty means nothing if the you can't understand it, that is why we have in-app guides on how to read the
        generated graphs. To make sure you are not overwhelmed with data we have only chosen the most important ones to
        display.</p>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import SecondSectionImage from "@/components/image/SecondSectionImage.vue";

@Options({
  components: {
    SecondSectionImage
  }
})
export default class SecondSection extends Vue {

}
</script>
<style scoped>
h2 {
  font-weight: 600;
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.section {
  margin-top: 120px;
  text-align: left;
  display: grid;
  grid-template-columns: 50% auto;
  margin-left: 120px;
  margin-right: 120px;
}

.second-section-text {
  margin-left: 120px;
}

.second-section-svg-div {
  text-align: left;
  margin-top: -50px;
  margin-right: -120px;
}

@media (max-width: 1280px) {
  .section {
    margin-right: 60px;
  }

  .second-section-svg-div {
    margin-left: calc(-120px);
  }
}
</style>
