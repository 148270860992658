import axios from "axios";
import {getJwtToken} from "@/util/auth_util";

export type UserResponse = {
    name: string,
    email: string,
    accountType: string,
    verified: boolean,
}

let userData: UserResponse | null = null;

export async function getUserDetails(): Promise<UserResponse> {
    if (userData != null) {
        return userData;
    }

    const response = await axios.get(process.env.VUE_APP_API_URL + "/user", {
        headers: {
            "Authorization": `Bearer ${getJwtToken()}`
        }
    });

    userData = response.data;

    return response.data;
}
