<template>
  <div class="section-image">
    <div class="absolute box"/>
    <BarChartImage class="absolute bar"/>
    <LineChartImage class="absolute line"/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import BarChartImage from '@/assets/img/chart/bar_chart.svg'
import LineChartImage from '@/assets/img/chart/line_chart.svg'

@Options({
  components: {
    BarChartImage,
    LineChartImage
  }
})
export default class SecondSectionImage extends Vue {

}
</script>
<style scoped>
.section-image {
  position: relative;
  width: 593px;
  height: 469px;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
}

.box {
  width: 591px;
  height: 266px;
  left: 1px;
  top: 206px;
  border-radius: 24px;
  background: #EAE8F2;
}

.bar {
  left: 48px;
  top: 1px;
  box-shadow: 0 7px 24px rgba(0, 0, 0, 0.07);
}

.line {
  top: 318px;
  left: 95px;
}
</style>
