
import {Options, Vue} from 'vue-class-component';
import FeaturePanel from "@/components/section/features/FeaturePanel.vue";
import ClockIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/clock.svg"
import GridIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/grid.svg"
import BarChartIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/bar-chart.svg"
import PocketIcon from "@timenaut/timenaut-vue-common/lib/assets/icons/feather/pocket.svg"

@Options({
  components: {
    FeaturePanel,
    ClockIcon,
    GridIcon,
    BarChartIcon,
    PocketIcon,
  }
})
export default class FeaturesSection extends Vue {

}
