
import {Options, Vue} from 'vue-class-component';
import Background from "@timenaut/timenaut-vue-common/lib/assets/img/login_bg.svg";
import ManagementNavigation from "@/components/ManagementNavigation.vue"

@Options({
  components: {
    Background,
    ManagementNavigation
  },
})
export default class AuthTemplate extends Vue {
}
