
import {Options, Vue} from 'vue-class-component';
import StarSvg from "@/assets/img/star.svg"

@Options({
  components: {
    StarSvg
  }
})
export default class StorySection extends Vue {

}
