<template>
  <div>
    <Alert v-if="cancelAlert" class="alert" type="error" @close="cancelAlert = false">
      Payment cancelled. Please try again or <a href="mailto:support@timenaut.app">contact support</a>.
    </Alert>
    <Alert v-if="successAlert" class="alert" type="success" @close="successAlert = false">
      Payment confirmed. Thanks for purchasing Timenaut!
    </Alert>
    <Alert v-if="verificationAlert" class="alert" type="info" not-closable>
      Please confirm your email address to complete your profile.
      <span v-if="notSentConfirmation">Didn’t receive an email? </span>
        <a v-if="notSentConfirmation" @click="sendConfirmationLink" href="#">Resend confirmation link</a>
    </Alert>

    <div class="user-page">
      <component :is="productStatus"></component>
      <UserDetails :userDetails="userDetails"/>
    </div>
  </div>
</template>

<script lang="ts">
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';
import UserDetails from "@/components/user/UserDetails.vue";
import FreeProductDescription from "@/components/user/FreeProductDescription.vue";
import PaidProductDescription from "@/components/user/PaidProductDescription.vue";
import {getUserDetails, UserResponse} from "@/util/user_util";
import {Alert} from "@timenaut/timenaut-vue-common"
import {resendConfirmationLink, verifyUser} from "@/util/auth_util";

@Options({
  components: {
    UserDetails,
    FreeProductDescription,
    PaidProductDescription,
    Alert
  },
})
export default class UserPage extends Vue {
  productStatus: any = FreeProductDescription;

  verificationAlert = false;
  successAlert = false;
  cancelAlert = false;
  notSentConfirmation = true;

  userDetails: UserResponse | {} = reactive({});

  mounted() {
    if (this.$route.query.success !== undefined) {
      this.successAlert = true;
    }

    if (this.$route.query.cancel !== undefined) {
      this.cancelAlert = true;
    }

    if (this.$route.params.verificationCode !== undefined) {
      verifyUser(this.$route.params.verificationCode as string);
    }

    getUserDetails().then(res => {
      this.userDetails = res;

      if (res.accountType === "PREMIUM") {
        this.productStatus = PaidProductDescription;
      }

      if (!res.verified && this.$route.params.verificationCode === undefined) {
        this.verificationAlert = true;
      }
    })
  }

  sendConfirmationLink() {
    this.notSentConfirmation = false;
    resendConfirmationLink();
  }
}
</script>
<style scoped>
.user-page {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.alert {
  width: inherit;
  margin-bottom: 20px;
}
</style>
