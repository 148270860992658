<template>
  <div class="section">
    <div class="third-section-text">
      <h2>Track your progress with<br>beautiful reports</h2>
      <p>To progress in life you are bound to look back sometimes. Fortunately, Timenaut does that for you with simple
        aesthetically pleasing displays of what has changed and how much compared to the past.</p>
    </div>
    <div class="third-section-svg-div">
      <ThirdSectionImage/>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import ThirdSectionImage from "@/components/image/ThirdSectionImage.vue";

@Options({
  components: {
    ThirdSectionImage
  }
})
export default class ThirdSection extends Vue {

}
</script>
<style scoped>
h2 {
  font-weight: 600;
  font-size: 32px;
  margin-top: 40px;
  margin-bottom: 40px;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
}

.section {
  margin-top: 120px;
  text-align: left;
  display: grid;
  grid-template-columns: 50% auto;
  margin-left: 120px;
  margin-right: 120px;
}

.third-section-text {
  margin-right: 120px;
}

.third-section-svg-div {
  overflow: hidden;
  margin-right: -120px;
}

img {
  margin-top: -100px;
}
</style>
