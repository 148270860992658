
import {Options, Vue} from 'vue-class-component';
import BarChartImage from '@/assets/img/chart/bar_chart.svg'
import LineChartImage from '@/assets/img/chart/line_chart.svg'

@Options({
  components: {
    BarChartImage,
    LineChartImage
  }
})
export default class SecondSectionImage extends Vue {

}
