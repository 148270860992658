<template>
  <div id="home">
    <div class="full-height">
      <Navigation/>
      <Landing/>
    </div>
    <div class="content">
      <FirstSection class="limit-width first-section"/>
      <SecondSection class="limit-width second-section"/>
      <ThirdSection class="limit-width third-section"/>
      <ProblemsSection class="limit-width"/>
      <FeaturesSection class="limit-width"/>
      <PricingSection class="max-width"/>
      <StorySection/>
      <div class="footer max-width">
        <DownloadSection/>
        <Footer dark/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import Navigation from '@/components/Navigation.vue'
import Footer from '@/components/Footer.vue'
import Landing from '@/components/Landing.vue'
import FirstSection from '@/components/section/FirstSection.vue'
import SecondSection from '@/components/section/SecondSection.vue'
import ThirdSection from '@/components/section/ThirdSection.vue'
import ProblemsSection from '@/components/section/ProblemsSection.vue'
import FeaturesSection from '@/components/section/features/FeaturesSection.vue'
import PricingSection from '@/components/section/PricingSection.vue'
import StorySection from '@/components/section/StorySection.vue'
import DownloadSection from '@/components/section/DownloadSection.vue'

@Options({
  components: {
    Navigation,
    Landing,
    FirstSection,
    SecondSection,
    ThirdSection,
    ProblemsSection,
    FeaturesSection,
    PricingSection,
    StorySection,
    DownloadSection,
    Footer
  },
})
export default class HomePage extends Vue {
}
</script>
<style scoped>
.limit-width {
  max-width: 1440px;
}

.max-width {
  width: 100%;
}

.full-height {
  height: 100%;
  background: linear-gradient(180deg, #379FFF 0%, #F4B4C7 71.35%, #FFCF5C 100%);
  padding: 20px;
  min-height: 1260px;
}

.footer {
  background: linear-gradient(180deg, #379FFF 0%, #F4B4C7 100.35%);
}

#home {
  height: calc(100% + 100px);
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.first-section {
  margin-top: 200px;
}

.second-section {
  margin-top: 200px;
}

.third-section {
  margin-top: 200px;
}
</style>
