
import {Options, Vue} from 'vue-class-component';
import {Button, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import ManagementNavigation from "@/components/ManagementNavigation.vue"
import axios from 'axios';

@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    ManagementNavigation
  },
})
export default class SuccessPage extends Vue {
  $refs!: {
    downloadLink: HTMLFormElement
  }

  linkVisible: boolean = false;
  downloadLink: String = "";

  mounted() {
    let os = this.$route.query.os;

    let downloadUrl = "/public/download"
    if (os != undefined) {
      downloadUrl += `?os=${os}`
    }
    axios.get(process.env.VUE_APP_API_URL + downloadUrl)
        .then(res => {
          this.linkVisible = true;
          this.downloadLink = res.data;

          window.location = res.data;
        })
  }
}
