<template>
  <div class="section-image">
    <ProductivityCardImage class="absolute card-1"/>
    <ProductiveHoursCardImage class="absolute card-2"/>
    <TotalTimeCardImage class="absolute card-3"/>
    <DistractedTimeCardImage class="absolute card-4"/>
    <PieChart1Image class="absolute pie-1"/>
    <PieChart2Image class="absolute pie-2"/>
    <PieChart3Image class="absolute pie-3"/>
    <PieChart4Image class="absolute pie-4"/>
    <PieChart5Image class="absolute pie-5"/>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import ProductivityCardImage from '@/assets/img/chart/productivity_card.svg'
import ProductiveHoursCardImage from '@/assets/img/chart/productive_hours_card.svg'
import TotalTimeCardImage from '@/assets/img/chart/total_time_card.svg'
import DistractedTimeCardImage from '@/assets/img/chart/distracted_time_card.svg'
import PieChart1Image from '@/assets/img/chart/pie_charts/pie_chart_1.svg'
import PieChart2Image from '@/assets/img/chart/pie_charts/pie_chart_2.svg'
import PieChart3Image from '@/assets/img/chart/pie_charts/pie_chart_3.svg'
import PieChart4Image from '@/assets/img/chart/pie_charts/pie_chart_4.svg'
import PieChart5Image from '@/assets/img/chart/pie_charts/pie_chart_5.svg'

@Options({
  components: {
    ProductivityCardImage,
    ProductiveHoursCardImage,
    TotalTimeCardImage,
    DistractedTimeCardImage,
    PieChart1Image,
    PieChart2Image,
    PieChart3Image,
    PieChart4Image,
    PieChart5Image,
  }
})
export default class ThirdSectionImage extends Vue {

}
</script>
<style scoped>
.section-image {
  position: relative;
  width: 620px;
  height: 469px;
}

.absolute {
  position: absolute;
  left: 0;
  right: 0;
}

.card-1 {
  left: 11px;
  top: 101px;
}

.card-2 {
  left: 167px;
  top: 81px;
  box-shadow: 0 7px 64px rgba(0, 0, 0, 0.07);
}

.card-3 {
  left: 323px;
  top: 101px;
}

.card-4 {
  left: 479px;
  top: 101px;
}

.pie-1 {
  left: 10px;
  top: 344px;
}

.pie-2 {
  left: 125px;
  top: 340px;
}

.pie-3 {
  left: 243px;
  top: 333px;
}

.pie-4 {
  left: 368px;
  top: 340px;
}

.pie-5 {
  left: 503px;
  top: 344px;
  box-shadow: 0px 26px 15px -22px rgba(22, 5, 107, 0.07);
}
</style>
