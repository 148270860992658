
import {Options, Vue} from 'vue-class-component';
import {Button} from "@timenaut/timenaut-vue-common";
import WindowsIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/windows.svg"
import MacOSIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/macos.svg"
import LinuxIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/linux.svg"

@Options({
  components: {
    Button,
    WindowsIcon,
    MacOSIcon,
    LinuxIcon
  }
})
export default class DownloadSection extends Vue {

}
