
import {Options, Vue} from 'vue-class-component';
import {TextButton, TextInput} from '@timenaut/timenaut-vue-common'
import {UserResponse} from "@/util/user_util";
import ProfilePictureSvg from "@timenaut/timenaut-vue-common/lib/assets/img/profile-picture.svg"

@Options({
  components: {
    TextInput,
    TextButton,
    ProfilePictureSvg,
  },
  props: {
    userDetails: {
      type: Object
    }
  }
})
export default class UserDetails extends Vue {
  userDetails?: UserResponse;
}
