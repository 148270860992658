<template>
  <div class="section">
    <h2>Download Timenaut for free</h2>
    <p>Free 14-day trial with all features enabled, then automatically switches to free version.
      <br>No credit card details required, no commitment. </p>
    <div class="download-buttons">
      <div class="button-description">
        <router-link to="/download?os=windows">
          <Button class="download-button">
            <template v-slot:icon-right>
              <WindowsIcon class="os-icon"/>
            </template>
            Download for Windows
          </Button>
        </router-link>
        <span>Tested with Windows 10</span>
      </div>
      <div class="button-description">
        <router-link to="/download?os=macos">
          <Button class="download-button">
            <template v-slot:icon-right>
              <MacOSIcon class="os-icon mac-icon"/>
            </template>
            Download for MacOS
          </Button>
        </router-link>
        <span>Tested with macOS 10.15</span>
      </div>
      <div class="button-description">
        <router-link to="/download?os=linux">
          <Button class="download-button">
            <template v-slot:icon-right>
              <LinuxIcon class="os-icon"/>
            </template>
            Download for Linux
          </Button>
        </router-link>
        <span>Tested with Ubuntu 20.04</span>
        <span>Required X Windows System</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {Button} from "@timenaut/timenaut-vue-common";
import WindowsIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/windows.svg"
import MacOSIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/macos.svg"
import LinuxIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/linux.svg"

@Options({
  components: {
    Button,
    WindowsIcon,
    MacOSIcon,
    LinuxIcon
  }
})
export default class DownloadSection extends Vue {

}
</script>
<style scoped>
.section {
  padding-top: 120px;
  padding-bottom: 150px;
  text-align: center;
  margin-left: 120px;
  margin-right: 120px;
}

h2 {
  font-size: 52px;
}

h2, p {
  color: white;
}

.download-buttons {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.button-description {
  display: flex;
  flex-direction: column;
}

span {
  color: white;
  font-size: 14px;
}

.download-button {
  height: 80px;
  width: 320px;
  margin: 8px;
}

.os-icon {
  margin-left: 20px;
  margin-bottom: 2px;
}

.mac-icon {
  margin-bottom: 5px;
}

@media (max-width: 1280px) {
  .download-buttons {
    flex-direction: column;
    align-items: center;
  }
}
</style>
