
import {Options, Vue} from 'vue-class-component';
import TimenautSvg from "@timenaut/timenaut-vue-common/lib/assets/img/timenaut.svg"
import FacebookSvg from "@timenaut/timenaut-vue-common/lib/assets/icons/logos/facebook.svg"
import LinkedinSvg from "@timenaut/timenaut-vue-common/lib/assets/icons/logos/linkedin.svg"

@Options({
  props: {
    dark: {
      type: Boolean,
      default: false
    }
  },
  components: {
    TimenautSvg,
    FacebookSvg,
    LinkedinSvg,
  }
})
export default class extends Vue {
  dark!: boolean
}
