
import {Options, Vue} from 'vue-class-component';
import SecondSectionImage from "@/components/image/SecondSectionImage.vue";

@Options({
  components: {
    SecondSectionImage
  }
})
export default class SecondSection extends Vue {

}
