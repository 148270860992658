
import {Options, Vue} from 'vue-class-component';
import {Button} from "@timenaut/timenaut-vue-common";
import WindowsIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/windows.svg";
import MaxOsIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/macos.svg";
import LinuxIcon from "@timenaut/timenaut-vue-common/lib/assets/img/os/linux.svg";
import AppSvg from "@/assets/img/app.svg";

@Options({
  components: {
    Button,
    WindowsIcon,
    MaxOsIcon,
    LinuxIcon,
    AppSvg
  }
})
export default class Landing extends Vue {

}
