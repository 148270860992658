
import {Options, Vue} from 'vue-class-component';
import {Button, TextButton, TextInput} from "@timenaut/timenaut-vue-common"
import ManagementNavigation from "@/components/ManagementNavigation.vue"

@Options({
  components: {
    TextInput,
    Button,
    TextButton,
    ManagementNavigation
  },
})
export default class SuccessPage extends Vue {
}
