
import {reactive} from 'vue';
import {Options, Vue} from 'vue-class-component';
import UserDetails from "@/components/user/UserDetails.vue";
import FreeProductDescription from "@/components/user/FreeProductDescription.vue";
import PaidProductDescription from "@/components/user/PaidProductDescription.vue";
import {getUserDetails, UserResponse} from "@/util/user_util";
import {Alert} from "@timenaut/timenaut-vue-common"
import {resendConfirmationLink, verifyUser} from "@/util/auth_util";

@Options({
  components: {
    UserDetails,
    FreeProductDescription,
    PaidProductDescription,
    Alert
  },
})
export default class UserPage extends Vue {
  productStatus: any = FreeProductDescription;

  verificationAlert = false;
  successAlert = false;
  cancelAlert = false;
  notSentConfirmation = true;

  userDetails: UserResponse | {} = reactive({});

  mounted() {
    if (this.$route.query.success !== undefined) {
      this.successAlert = true;
    }

    if (this.$route.query.cancel !== undefined) {
      this.cancelAlert = true;
    }

    if (this.$route.params.verificationCode !== undefined) {
      verifyUser(this.$route.params.verificationCode as string);
    }

    getUserDetails().then(res => {
      this.userDetails = res;

      if (res.accountType === "PREMIUM") {
        this.productStatus = PaidProductDescription;
      }

      if (!res.verified && this.$route.params.verificationCode === undefined) {
        this.verificationAlert = true;
      }
    })
  }

  sendConfirmationLink() {
    this.notSentConfirmation = false;
    resendConfirmationLink();
  }
}
