
import {Options, Vue} from 'vue-class-component';
import FirstSectionImage from "@/components/image/FirstSectionImage.vue";

@Options({
  components: {
    FirstSectionImage
  }
})
export default class FirstSection extends Vue {

}
